<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar"></i> <span class="h5"> Estadísticas </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LAS COTIZACIONES DE CERTIFICACIÓN
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="fas fa-file-invoice-dollar fa-3x ico"> </i>
                            <br>
                            <span class="lg-numero">{{listaCotizaciones.length}}</span>
                            <br>
                            <span class="text-muted h6">Cotizaciones de certificación registradas</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de las cotizaciones de certificación </span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="text-center justify-content-center">
                        <!-- <b-col cols="6" class="my-2">
                            <b-button block :to="{name: 'Clientes cliente'}" size="md" variant="custom">
                                <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br>Volver
                            </b-button>
                        </b-col> -->
                        <b-col cols="6" class="my-2 ">
                            <b-button block size="md" variant="custom" :to="{name: 'Gestión de cotización cliente'}">
                                <i class="fas fa-plus-circle fa-3x pb-1" style="inline-size: auto"></i>
                                <br>Nueva cotización
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Cotizaciones de certificación registradas</span>
                </CCardHeader>

                <CCardBody>
                    <b-row>
                        <b-col md="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="7">

                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table bordered hover show-empty mediun responsive outlined :items="listaCotizaciones" :fields="camposCotizaciones" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #table-colgroup="camposCotizaciones">
                                    <col v-for="field in camposCotizaciones.fields" :key="field.key" :style="{ width: field.key === 'index' ? '5%' : field.key === 'fechaCreacion' ? '10%' : field.key === 'normas' ? '30%' : field.key === 'opciones' ? '10%' : '30%'}">
                                </template>
                                <template v-slot:cell(fechaCreacion)="data">
                                    {{data.value.substr(0,10)}}
                                </template>
                                <template v-slot:cell(normas)="data">
                                    {{
                                       String('* '+listaNormas.filter((x) => data.value.includes(x.idNorma)).map(y => ` ${y.descripcion}, `).join(' * '))
                                    }}
                                </template>

                                <template v-slot:cell(opciones)="param">
                                    <b-button size="sm" class="mr-1 mb-1" variant="dark" v-c-tooltip.hover.click="'Ver detalles'" :to="{name: 'Gestión de cotización cliente', params: {id: param.item.idCotizacionCertificacion}}">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                    <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Aprobar Cotización'" @click="cambiarEstadoCotizacion(param)" variant="success">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-check  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                    <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarCotizacion(param)" variant="danger">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>

                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" class="my-0 float-right" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    data() {
        return {
            datosSession: {
                idCliente: '',
            },
            listaCotizaciones: [],
            camposCotizaciones: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "razonSocial",
                    label: "Empresa",
                    class: "text-center"
                },
                {
                    key: "normas",
                    label: "Normas",
                    class: "text-center",
                },
                {
                    key: "fechaCreacion",
                    label: "Fecha",
                    class: "text-center",
                },
                {
                    label: "",
                    key: "opciones",
                    class: "text-center",
                },
               
            ],
            filasTotales: 0,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            filter: null,
            filterOn: [],
            listaNormas: [{
                    idNorma: 1,
                    descripcion: 'ISO 9001: 2015'
                },
                {
                    idNorma: 2,
                    descripcion: 'ISO 14001: 2015'
                },
                {
                    idNorma: 3,
                    descripcion: 'ISO 45001: 2018'
                },
                {
                    idNorma: 4,
                    descripcion: 'ISO 37001: 2016'
                },
                {
                    idNorma: 5,
                    descripcion: 'ISO 27001: 2013'
                },
                {
                    idNorma: 6,
                    descripcion: 'ISO 20000: 2011'
                },
            ],

        }
    },
    methods: {
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        listarCotizaciones() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/comercial/listar-cotizaciones-certificacion", {
                    params: {
                        idCliente: me.datosSession.idCliente,
                    }
                })
                .then(function (response) {
                    me.listaCotizaciones = response.data;
                    me.filasTotales = me.listaCotizaciones.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        cambiarEstadoCotizacion(param) {
            let me = this;
            axios
                .post(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/comercial/cambiar-estado-cotizacion-iqc", {
                   idSubCliente: param.item.idSubCliente,
                    estado: 3
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`,
                    }
                })
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarCotizaciones();
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },

        eliminarCotizacion(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar la cotizacion?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/comercial/eliminar-cotizacion-certificacion", {
                            idCotizacionCertificacion: param.item.idCotizacionCertificacion,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarCotizaciones();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarCotizaciones();
        }

        // this.listarCotizaciones();
        // this.obtenerDepartamento();
    }
}
</script>
